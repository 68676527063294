<template>
	<ul class="qs-sidebar-submenu">
		<li v-show="!item.meta.isShow" :class="
        currentRoute == item.path
          ? 'qs-sidebar-submenu-item qs-sidebar-ac clearfix'
          : 'qs-sidebar-submenu-item clearfix'
      " v-for="(item, index) in children" :key="index" @click="handleJump(item)">
			<div class="qs-sidebar-submenu-text">
				{{ item.meta.title }} {{ item.meta.activeMenu }}
			</div>
			<!-- <div v-if="item.meta.num" :class=" item.meta.countIsshow ? 'qs-sidebar-submenu-num' : 'displayNone'">{{ item.meta.num }}</div>
            <div v-else :class="item.meta.countIsshow ? 'qs-sidebar-submenu-num' : 'displayNone'">0</div> -->
		</li>
	</ul>
</template>

<script>
	export default {
		props: ['children'],
		data() {
			return {}
		},
		computed: {
			currentRoute() {
				return this.$route.meta.activeMenu ?
					this.$route.meta.activeMenu :
					this.$route.path
			},
		},
		methods: {
			handleJump(item) {
				localStorage.setItem('agent_resource_id', '')
				localStorage.setItem('supplier_resource_id', '')
				localStorage.setItem('cdr_supplier_id','')
				localStorage.setItem('supplier_id', '')
				this.$router.push(item.path)
			},
		},
	}
</script>

<style lang="scss" scoped>
	// 二级
	.qs-sidebar-submenu {
		white-space: nowrap;
		background: $menuLevel2BgColor;

		.qs-sidebar-submenu-item {
			height: 50px;
			line-height: 50px;
			padding: 0 24px;
			cursor: pointer;
			position: relative;

			&:hover {
				border-radius: 0;

				.qs-sidebar-submenu-text {
					color: $themeColor;
					// &::after {
					//     content: '';
					//     display: block;
					//     width: 3px;
					//     height: 100%;
					//     background: #2469FF;
					//     position: absolute;
					//     top: 0;
					//     right: 0;
					// }
				}

				.qs-sidebar-submenu-num {
					color: #2469FF;
				}
			}

			.qs-sidebar-submenu-text {
				display: inline-block;
				box-sizing: border-box;
				padding-left: 16px;
				color: $themeTextColor;
				font-size: 14px;
				max-width: 162px;
				overflow: hidden;
			}

			.qs-sidebar-submenu-num {
				display: inline-block;
				color: #808eaa;
				font-size: 14px;
				padding-right: 10px;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}

			&.qs-sidebar-ac {
				border-radius: 0;
				color: $themeColor;

				.qs-sidebar-submenu-text {
					color: $themeColor;
					// &::after {
					//     content: '';
					//     display: block;
					//     width: 3px;
					//     height: 100%;
					//     background: #2469FF;
					//     position: absolute;
					//     top: 0;
					//     right: 0;
					// }
				}

				.qs-sidebar-submenu-num {
					color: #2469FF;
				}
			}
		}
	}
</style>