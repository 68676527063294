import Vue from 'vue'
import Vuex from 'vuex'
import home from './modules/home'
import routingTable from './modules/routingTable'
import user from './modules/user'
import common from './modules/common'
import layout from './modules/layout'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    home,
    routingTable,
    user,
	common,
    layout
  }
})
