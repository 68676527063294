import service from '@/axios/request';
import {
	getToken
} from '@/utils/auth'
/**
 * 方法名称：日期格式化（带参数）
 * 描述：将13位时间戳转换成需要展示的时间格式
 * @param  {Number}  timestamp  时间戳
 * @param  {String}  formats  转换的时间格式
 * @returns {String}  格式化后的时间字符串
 * @example
 * dateFormat(1625455267920,'Y-m-d')   ===>  2021-07-05
 * dateFormat(1625455267920,'Y-m-d H:i:s')   ===>  2021-07-05 11:21:07
 **/
const dateFormat = function(timestamp, formats) {
	// formats格式包括
	// 1. Y-m-d Y年m月d日
	// 2. Y-m-d H:i:s Y年m月d日 H时i分
	formats = formats || 'Y-m-d'

	const zero = function(value) {
		if (value < 10) {
			return '0' + value
		}
		return value
	}

	// if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") > -1) {
	//     timestamp = String(timestamp).replace(/\-/g, "/")
	// }

	const myDate = timestamp ? new Date(timestamp) : new Date()

	const year = myDate.getFullYear()
	const month = zero(myDate.getMonth() + 1)
	const day = zero(myDate.getDate())

	const hour = zero(myDate.getHours())
	const minite = zero(myDate.getMinutes())
	const second = zero(myDate.getSeconds())

	return formats.replace(/Y|m|d|H|i|s/gi, function(matches) {
		return {
			Y: year,
			m: month,
			d: day,
			H: hour,
			i: minite,
			s: second
		} [matches]
	})
}

/**
 * 方法名称：毫秒数转化为时间段（时分秒补零）
 * 描述：将13位时间戳转换成补零之后的时间格式
 * @param  {Number}  time  时间戳
 * @returns {String}  补零之后的时间格式
 * @example
 * timeFormat(1625443688000)   ===>  08:08:08
 **/
const timeFormat = function(time) {
	let hours = Math.floor(time / 1000 / 60 / 60 % 24)
	let minutes = Math.floor(time / 1000 / 60 % 60)
	let seconds = Math.floor(time / 1000 % 60)
	if (seconds < 10) {
		seconds = '0' + seconds
	}
	if (minutes < 10) {
		minutes = '0' + minutes
	}
	if (hours < 10) {
		hours = '0' + hours
	}
	return `${hours}:${minutes}:${seconds}`
}

/**
 * 方法名称：固定时间距离当前时间的时间间隔格式化
 * 描述：将13位时间戳距离当前的时间间隔装换成需要的格式，间隔小于0==>已开始  间隔小于一天==>还剩*小时*分钟  间隔大于一天==>还剩*天
 * @param  {Number}  time  时间戳
 * @returns {String}  距离当前的时间间隔的格式
 * @example
 * dateIntervalFormat(1625443688000)   ===>  已开始
 * dateIntervalFormat(1625443688000)   ===>  还剩1小时10分钟
 * dateIntervalFormat(1625443688000)   ===>  还剩5天
 **/
const dateIntervalFormat = function(time) {
	const
		passedTime = new Date(time).getTime() - new Date().getTime()
	const day = Math.floor(passedTime / 86400000)
	const hour = Math.floor(passedTime / 3600000)
	const minute = Math.floor(passedTime % 3600000 / 60000)
	if (passedTime <= 0) {
		return '已开始'
	} else if (passedTime < 86400000) {
		return `还剩${hour ? `${hour}小时` : ''}${minute}分钟`
	} else {
		return `还剩${day}天`
	}
}

/**
 * 方法名称：毫秒数转化为时间段（年月日时分补零）
 * 描述：将13位时间戳转换成补零之后的时间格式
 * @param  {Number}  dateTime  时间戳
 * @returns {String}  补零之后的时间格式
 * @example
 * dateFormatter(1625443688000)   ===> 2021-07-05 08:08:08
 **/
const dateFormatter = function(dateTime) {
	const date = new Date(dateTime)
	const Y = date.getFullYear() + '-'
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	const finalDate = Y + M + D + h + m + s
	return finalDate
}

/**
 * 方法名称：数字和中文转换
 * 描述：将数字转换成中文字符串
 * @param  {Number}  num  数字
 * @returns {String}  数字转化成的中文字符串
 * @example
 * toChinesNum(140)   ===> 一百四十
 **/
const toChinesNum = function(num) {
	const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] // changeNum[0] = "零"
	const unit = ['', '十', '百', '千', '万']
	num = parseInt(num)
	const getWan = (temp) => {
		const strArr = temp.toString().split('').reverse()
		let newNum = ''
		for (var i = 0; i < strArr.length; i++) {
			newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' :
				changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
		}
		return newNum
	}
	const overWan = Math.floor(num / 10000)
	let noWan = num % 10000
	if (noWan.toString().length < 4) noWan = '0' + noWan
	return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

/**
 * 方法名称：获取未来几天的日期
 * 描述：day为正数后几天的时间  day为正数前几天的时间
 * @param  {Number}  day  数字
 * @returns {String} 未来几天的时间日期
 * @example
 * getFutureData(3)   ===> 7.8
 * getFutureData(-3)   ===> 7.2
 **/
const getFutureData = function(day) {
	const date = new Date()
	date.setDate(date.getDate() + day)
	const time2 = (date.getMonth() + 1) + '.' + date.getDate()
	return time2
}

/**
 * 方法名称：姓名脱敏
 * 描述：保留姓，之后替换成想要的字符串
 * @param  {String}  str  名字
 * @param  {String}  replaceStr  替换的字符串
 * @returns {String} 替换之后的字符串
 * @example
 * noPassByName('张三','老师')   ===> 张老师
 * noPassByName('张三','学生')   ===> 张学生
 **/
const noPassByName = function(str, replaceStr) {
	if (String(str) === '') {
		return ''
	} else {
		return str.substring(0, 1) + replaceStr
	}
}

/**
 * 方法名称：手机号脱敏处理
 * 描述：保留姓，之后替换成想要的字符串
 * @param  {String}  str  名字
 * @param  {String}  replaceStr  替换的字符串
 * @returns {String} 替换之后的字符串
 * @example
 * noPassByPhone('18311114444','****')   ===> 183****4444
 **/
const noPassByPhone = function(str, replaceStr) {
	if (String(str) === '' || String(str) === 'undefined') {
		return ''
	} else {
		const len = str.length
		return str.substring(0, 3) + replaceStr + str.substring(len - 4, len)
	}
}

/**
 * 方法名称：身份证脱敏处理
 * 描述：保留前三位和后四位，中间用*号代替
 * @param  {String}  str  身份证号
 * @returns {String} 替换之后的字符串
 * @example
 * noPassByIdent('140555123456781234')   ===> 140***********1234
 **/
const noPassByIdent = function(str) {
	if (String(str) === '') {
		return ''
	} else {
		const len = str.length
		return str.substring(0, 3) + '***********' + str.substring(len - 4, len)
	}
}

/**
 * 方法名称：银行卡号脱敏处理
 * 描述：保留前三位和后四位，中间用*号代替
 * @param  {String}  str  银行卡号
 * @returns {String} 替换之后的字符串
 * @example
 * noPassByBankCard('666555555588888')   ===> 666***********888
 **/
const noPassByBankCard = function(str) {
	if (String(str) === '') {
		return ''
	} else {
		const len = str.length
		return str.substring(0, 4) + '***********' + str.substring(len - 4, len)
	}
}

/**
 * 方法名称：根据对应的格式获取未来时间
 * 描述：根据对应的格式获取对应的未来的时间
 * @param  {String}  strInterval  日期格式（s,n,h,d,w,q,m,y）
 * @param  {Number}  Number  对应的数字
 * @returns {Date} 返回对应的Date对象
 * @example
 * futureTime('d',5)   ===> Sat Jul 10 2021 13:59:03 GMT+0800 (中国标准时间)
 **/
const futureTime = function(strInterval, Number) {
	const dtTmp = new Date()
	switch (strInterval) {
		case 's':
			return new Date(Date.parse(dtTmp) + (1000 * Number))
		case 'n':
			return new Date(Date.parse(dtTmp) + (60000 * Number))
		case 'h':
			return new Date(Date.parse(dtTmp) + (3600000 * Number))
		case 'd':
			return new Date(Date.parse(dtTmp) + (86400000 * Number))
		case 'w':
			return new Date(Date.parse(dtTmp) + ((86400000 * 7) * Number))
		case 'q':
			return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + Number * 3, dtTmp.getDate(), dtTmp.getHours(),
				dtTmp.getMinutes(), dtTmp.getSeconds())
		case 'm':
			return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + Number, dtTmp.getDate(), dtTmp.getHours(),
				dtTmp.getMinutes(), dtTmp.getSeconds())
		case 'y':
			return new Date((dtTmp.getFullYear() + Number), dtTmp.getMonth(), dtTmp.getDate(), dtTmp.getHours(),
				dtTmp.getMinutes(), dtTmp.getSeconds())
	}
}

/**
 * 方法名称：高亮搜索结果中的关键字
 * 描述：高亮搜索结果中的关键字
 * @param  {String} value  需要处理的字符串
 * @param  {String} key    关键字
 * @param  {Number} length 截取长度
 * @return {html}          返回一个包含HTML的字符串
 **/
const highLight = function(value, key, length) {
	if (value) {
		if (key) {
			if (value.length > length) {
				if (value.indexOf(key) >= 0 && key.length > length) {
					return '<span>' + value.substring(0, length) + '…' + '</span>'
				} else {
					return value.substring(0, length).split(key).join('<span class="keywordStyle">' + key +
						'</span>') + '…'
				}
			} else {
				return value.split(key).join('<span class="keywordStyle">' + key + '</span>')
			}
		} else {
			if (value.length > length) {
				return value.substring(0, length) + '…'
			} else {
				return value
			}
		}
	} else {
		return ''
	}
}

/**
 * 方法名称：过去时间格式化
 * 描述：根据当前时间和传入的过去时间的间隔返回对应的话术  <30s ==> 刚刚  <60m ==> *分钟前   <24小时 ==> *小时前  <2天 ==> 1天前
 * @param {number} time 对应的时间戳
 * @returns {string}  对应的时间间隔处理话术
 * @example
 * formatFutureTime(1625455479366)   ===>   3小时前
 **/
const formatFutureTime = function(time) {
	if (('' + time).length === 10) {
		time = parseInt(time) * 1000
	} else {
		time = +time
	}
	const d = new Date(time)
	const now = Date.now()

	const diff = (now - d) / 1000

	if (diff < 30) {
		return '刚刚'
	} else if (diff < 3600) {
		return Math.ceil(diff / 60) + '分钟前'
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + '小时前'
	} else if (diff < 3600 * 24 * 2) {
		return '1天前'
	}
}

/**
 * 方法名称：分钟格式化
 * 描述：根据传入的分钟数返回*天*小时*分钟
 * @param {number} StatusMinute 分钟数
 * @returns {string} *天*小时*分钟
 * @example
 * formatSeconds(5)   ===>   5分钟
 * formatSeconds(100)   ===>   1小时40分钟
 **/
const formatSeconds = function(StatusMinute) {
	var day = parseInt(StatusMinute / 60 / 24)
	var hour = parseInt(StatusMinute / 60 % 24)
	var min = parseInt(StatusMinute % 60)
	StatusMinute = ''
	if (day > 0) {
		StatusMinute = day + '天'
	}
	if (hour > 0) {
		StatusMinute += hour + '小时'
	}
	if (min >= 0) {
		StatusMinute += parseFloat(min) + '分钟'
	}
	return StatusMinute
}

/**
 * 方法名称：根据经纬度距离计算函数
 * 描述：根据地点1和地点2的经纬度返回对应的距离话术  (<500 ===> <500m)  (>=500 && <1000  ===> 800m)  (>=1000 && <10000  ===> 5km)  (>= 10000 ===> >10km)
 * @param {number} lat1 地点1的经度
 * @param {number} lon1 地点1的纬度
 * @param {number} lat2 地点2的经度
 * @param {number} lon2 地点2的纬度
 * @returns {string}  根据距离返回对应的话术
 * @example
 * getDistance(37.796283,112.560168, 37.710531,112.639796)   ===>  >10km
 **/
const getDistance = function(lat1, lon1, lat2, lon2) {
	var radLat1 = (lat1 * Math.PI) / 180 // 将角度换算为弧度
	var radLat2 = (lat2 * Math.PI) / 180 // 将角度换算为弧度
	var a = radLat1 - radLat2
	var b = (lon1 * Math.PI) / 180 - (lon2 * Math.PI) / 180
	var s =
		2 *
		Math.asin(
			Math.sqrt(
				Math.pow(Math.sin(a / 2), 2) +
				Math.cos(radLat1) *
				Math.cos(radLat2) *
				Math.pow(Math.sin(b / 2), 2)
			)
		)
	s = s * 6378137.0
	if (Math.ceil(s) < 500) {
		return '<500m'
	} else if (Math.ceil(s) >= 500 && Math.ceil(s) < 1000) {
		return `${(Math.ceil(s) / 1).toFixed(1)}m`
	} else if (Math.ceil(s) >= 1000 && Math.ceil(s) < 10000) {
		return `${(Math.ceil(s) / 1000).toFixed(1)}km`
	} else if (Math.ceil(s) >= 10000) {
		return '>10km'
	}
}

/**
 * 方法名称：距离格式化
 * 描述：根据传入的距离返回对应的话术
 * @param {number} s 距离
 * @returns {string} 格式化后的距离话术
 * @example
 * formatDistance(200)   ===>   <500m
 * formatDistance(1500)   ===>   1.5km
 * formatDistance(20000000)   ===>  >10km
 **/
const formatDistance = function(s) {
	if (s < 500) {
		return '<500m'
	} else if (s >= 500 && s < 1000) {
		return `${(s / 1).toFixed(1)}m`
	} else if (s >= 1000 && s < 10000) {
		return `${(s / 1000).toFixed(1)}km`
	} else if (s >= 10000) {
		return '>10km'
	}
}

/**
 * 方法名称：获取一周的时间数组
 * 描述：获取一周的时间数组
 * @param {Number}timestamp 时间戳，默认是当前时间
 * @returns {Array} 一周的时间数组
 * @example
 * week();
 * // => [{fullDate: "2020-11-27", timestamp: 1606463756507, week: "星期五"}]
 */
const geiWeekToArr = function(timestamp) {
	const weekArray = new Array('日', '一', '二', '三', '四', '五', '六')
	const today = timestamp ? new Date(timestamp) : new Date()
	const dateArr = []
	for (let i = 0; i < 7; i++) {
		const timestamp = today.getTime() + i * 1000 * 60 * 60 * 24
		const newDate = new Date(timestamp)
		const year = newDate.getFullYear()
		const month = (parseInt(newDate.getMonth()) + 1) > 9 ? (parseInt(newDate.getMonth()) + 1) : '0' + (parseInt(
			newDate.getMonth()) + 1)
		const day = (newDate.getDate()) > 9 ? newDate.getDate() : '0' + newDate.getDate()
		const fullDate = `${year}-${month}-${day}`
		dateArr.push({
			fullDate: fullDate,
			timestamp: timestamp,
			week: `星期${weekArray[newDate.getDay()]}`
		})
	}
	return dateArr
}

/**
 * 主动防御
 * 对于我们操作的数据，尤其是由 API 接口返回的，时常会有一个很复杂的深层嵌套的数据结构。为了代码的健壮性，很多时候需要对每一层访问都作空值判断，就像这样：
 props.user &&
 props.user.posts &&
 props.user.posts[0] &&
 props.user.posts[0].comments &&
 props.user.posts[0].comments[0]
 代码看起来相当不美观，因此提供了一个非常简洁明了的原生的方式。
 *
 * @param p 属性列表
 * @param o 对象
 * @returns {*} 如果正常访问到，则返回对应的值，否则返回 null。
 * @example
 *
 * var props = {
 *  user: {
 *    post: [{
 *      comments: 'test'
 *    }]
 *  }
 * };
 * getIn(['user', 'post', 0, 'comments'], props);
 * // => test
 */
const getIn = function(p, o) {
	return p.reduce(function(xs, x) {
		return (xs && xs[x]) ? xs[x] : null
	}, o)
}

/**
 * 方法名称：根据身份证号判断性别与年龄
 * 描述：根据身份证号判断性别与年龄
 * @param {String} UUserCard  身份证号
 * @returns {Object} 包含性别和年龄的对象{ageNum，gender}
 */
const discriCard = function(UUserCard) {
	const ageNum = 0;
	let gender = '0'
	UUserCard.substring(6, 10) + '-' + UUserCard.substring(10, 12) + '-' + UUserCard.substring(12, 14) // 获取性别

	if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) {
		gender = '1'
	} else {
		gender = '2'
	} // 获取年龄

	var myDate = new Date()
	var month = myDate.getMonth() + 1
	var day = myDate.getDate()
	var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1
	if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12,
			14) <= day) {
		age++
	}
	return {
		ageNum: age,
		gender
	}
}

/**
 * 为数字加上单位：万或亿
 *
 * @param {number} number 输入数字.
 * @param {number} decimalDigit 小数点后最多位数，默认为2
 * @return {*} 加上单位后的数字
 * @example
 *
 * addChineseUnit(1000.01)
 * // => 1000.01
 *
 * addChineseUnit(10000)
 * // => 1万
 *
 * addChineseUnit(99000)
 * // => 9.9万
 *
 * addChineseUnit(566000)
 * // => 56.6万
 *
 * addChineseUnit(5660000)
 * // => 566万
 *
 * addChineseUnit(44440000)
 * // => 4444万
 *
 * addChineseUnit(11111000)
 * // => 1111.1万
 *
 * addChineseUnit(444400000)
 * // => 4.44亿
 *
 * addChineseUnit(400000000000000000000000)
 * // => 4000万亿亿
 *
 * addChineseUnit(4000000000000000000000000)
 * // => 4亿亿亿
 */
function addChineseUnit(number, decimalDigit) {
	if (decimalDigit === void 0) decimalDigit = 2

	var isNeedAbs = false
	if (number < 0) {
		isNeedAbs = true
		number = Math.abs(number)
	}

	var integer = Math.floor(number)
	var digit = getDigit(integer)
	// ['个', '十', '百', '千', '万', '十万', '百万', '千万'];
	var unit = []

	if (digit > 3) {
		var multiple = Math.floor(digit / 8)
		if (multiple >= 1) {
			var tmp = Math.floor(integer / Math.pow(10, 8 * multiple))
			unit.push(addWan(tmp, number, 8 * multiple, decimalDigit))
			for (var i = 0; i < multiple; i++) {
				unit.push('亿')
			}
			if (isNeedAbs) {
				return '-' + unit.join('')
			} else {
				return unit.join('')
			}
		} else {
			if (isNeedAbs) {
				return '-' + addWan(integer, number, 0, decimalDigit)
			} else {
				return addWan(integer, number, 0, decimalDigit)
			}
		}
	} else {
		if (isNeedAbs) {
			return '-' + number
		} else {
			return number
		}
	}
}

export function addWan(integer, number, mutiple, decimalDigit) {
	var digit = getDigit(integer)
	if (digit > 3) {
		var remainder = digit % 8
		// ‘十万’、‘百万’、‘千万’显示为‘万’
		if (remainder >= 5) {
			remainder = 4
		}
		return Math.floor(number / Math.pow(10, remainder + mutiple - decimalDigit)) / Math.pow(10, decimalDigit) + '万'
	} else {
		return Math.floor(number / Math.pow(10, mutiple - decimalDigit)) / Math.pow(10, decimalDigit)
	}
}

export function getDigit(integer) {
	var digit = -1
	while (integer >= 1) {
		digit++
		integer = integer / 10
	}
	return digit
}

/**
 * 方法名称：获取 AddDayCount天后的日期字符串
 * 描述：AddDayCount天后的日期字符串
 * @param {number} AddDayCount 天数
 * @returns {string} AddDayCount天后的日期字符串
 * @example
 * getDateStr(5)   ===>   07月10日
 **/
const getDateStr = function(AddDayCount) {
	const dd = new Date()
	dd.setDate(dd.getDate() + AddDayCount) // 获取AddDayCount天后的日期
	const y = dd.getFullYear()
	const m = dd.getMonth() + 1 // 获取当前月份的日期
	const d = dd.getDate()
	return (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
}

/**
 * 方法名称：获取 AddDayCount天后的星期字符串
 * 描述：AddDayCount天后的星期字符串
 * @param {number} AddDayCount 天数
 * @returns {string} AddDayCount天后的星期字符串
 * @example
 * getWeekStr(5)   ===>   周六
 **/
const getWeekStr = function(AddDayCount) {
	const dd = new Date()
	dd.setDate(dd.getDate() + AddDayCount) // 获取AddDayCount天后的日期
	const week = dd.getDay()
	switch (week) {
		case 0:
			return '周天'
		case 1:
			return '周一'
		case 2:
			return '周二'
		case 3:
			return '周三'
		case 4:
			return '周四'
		case 5:
			return '周五'
		case 6:
			return '周六'
	}
}

/**
 * 方法名称：获取url里面对应key的值
 * 描述：获取url里面对应key的值
 * @param {String} name url的key
 * @returns {string} url里面对应key的值
 * @example
 * getQueryString(name)   ===>   '用户名'
 **/
const getQueryString = function(name) {
	const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
	const r = window.location.search.substr(1).match(reg)
	if (r != null) {
		return decodeURIComponent(r[2])
	}

	return null
}

/**
 * 方法名称：判断是否是微信环境
 * 描述：判断是否是微信环境
 * @returns {Boolean} 是否的微信环境的布尔值
 * @example
 * isWxMini(   ===>   true/false
 **/
const isWxMini = function() {
	const ua = window.navigator.userAgent.toLowerCase()
	const isWx = ua.match(/MicroMessenger/i) + '' === 'micromessenger'
	if (isWx) {
		const isWxWork = ua.match(/WxWork/i) + '' === 'wxwork'
		if (ua.match(/Miniprogram/i)) {
			// 小程序
			return false
		} else if (isWxWork) {
			// 企业微信
			return true
		} else {
			// 微信
			return true
		}
	} else {
		// 其他
		return true
	}
}

// 节流：在单位时间内频繁触发事件，只生效一次（也就是只有第一次生效）
export function throttle(fn, delay) {
	// 开始时间
	var begin = 0;
	return function() {
		var cur = new Date().getTime(); // 记录当前最新时间
		// 如果 当前时间 - 开始时间 > 延迟时间
		if (cur - begin > delay) {
			fn.apply(this, arguments);
			begin = cur;
		}
	}
}
// 防抖：一段时间内连续触发事件，只执行最后一次
export function debounce(fn, delay) {
	let t = null;
	return function() {
		let firstClick = !t;
		if (t) clearTimeout(t)
		// 判断是不是第一次点击
		if (firstClick) {
			fn.apply(this, arguments)
		}

		t = setTimeout(() => {
			t = null
		}, delay);
	}
}

/**
 * @desc 函数防抖：双剑合璧版
 * @param func 函数
 * @param delay 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
const debounce_new = function(func, delay, immediate) {
	let timer;
	return function() {
		let context = this;

		if (timer) clearTimeout(timer);
		if (immediate) {
			// 立即执行
			let callNow = !timer;
			timer = setTimeout(() => {
				timer = null;
			}, delay)
			if (callNow) func.apply(context, arguments);
		} else {
			// 非立即执行
			timer = setTimeout(() => {
				func.apply(context, arguments);
			}, delay)
		}
	}
}


/**
 * 方法名称：根据生日计算年龄
 * 描述：根据生日转换成的时间格式返回对应的年龄
 * @param {Array} birthday 生日
 * @returns {Array} 年龄
 * @example
 * getAge([1993,06,14])   ===>  [28, 0, 22]
 **/
const getAge = function(birthday) {
	const date = new Date()
	const today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
	const age = today.map((value, index) => {
		return value - birthday[index]
	})
	if (age[2] < 0) {
		const lastMonth = new Date(today[0], today[1], 0)
		age[1]--
		age[2] += lastMonth.getDate()
	}
	if (age[1] < 0) {
		age[0]--
		age[1] += 12
	}
	return age
}

/**
 * 是否为空对象
 * @param {object} o
 * @returns {boolean}
 * @example
 *
 * isEmptyObject({});
 * // => true
 */
const isEmptyObject = function(o) {
	var t

	for (t in o) {
		return !1
	}

	return !0
}

/**
 * 金额格式化
 * 参数说明：
 * @param {number} number：要格式化的数字
 * @param {number} decimals：保留几位小数
 * @param {number} dec_point：小数点符号
 * @param {number} thousands_sep：千分位符号
 * */
const number_format = function(number, decimals, dec_point, thousands_sep) {
	number = (number + '').replace(/[^0-9+-Ee.]/g, '')
	var n = !isFinite(+number) ? 0 : +number
	var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
	var sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep
	var dec = (typeof dec_point === 'undefined') ? '.' : dec_point
	var s = ''

	var toFixedFix = function(n, prec) {
		var k = Math.pow(10, prec)
		return '' + Math.ceil(n * k) / k
	}

	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
	var re = /(-?\d+)(\d{3})/
	while (re.test(s[0])) {
		s[0] = s[0].replace(re, '$1' + sep + '$2')
	}

	if ((s[1] || '').length < prec) {
		s[1] = s[1] || ''
		s[1] += new Array(prec - s[1].length + 1).join('0')
	}
	return s.join(dec)
}
const isMobile = function() {
	if ((navigator.userAgent.match(
			/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
		return 'moblie'
	} else {
		return 'PC'
	}
}

// 批量替换字符串
const strReplace = function(str, find, replace) {
	str = str.replace(find, replace)
	if (str.indexOf(find) != -1) {
		strReplace(str, find, replace)
	}
	return str
}
// 判断是否是微信浏览器的函数
const isWeiXin = function() {
	var ua = window.navigator.userAgent.toLowerCase()
	if (ua.indexOf('micromessenger') != -1) {
		return true
	} else {
		return false
	}
}
// 判断是否是ios
const IsAppleStore = function() {
	var u = navigator.userAgent,
		app = navigator.appVersion
	var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // 如果输出结果是true就判定是android终端或者uc浏览器
	var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
	if (isAndroid) {
		return false
	} else if (isiOS) {
		return true
	}
}

//导出为文件流
const downLoadExcel = function(blob, filename = "模板") {
	let file = new File([blob], filename, blob);
	let aTag = document.createElement("a"); //创建一个a标签
	aTag.download = file.name;
	aTag.target = "_blank";
	let href = URL.createObjectURL(file); //获取url
	aTag.href = href;
	aTag.click();
	URL.revokeObjectURL(href); //释放url
}

// 导出为文件链接
const downloadFile = function(type, fileName) {
	let url = ''
	if (type == 1 || type == 2 || type == 4 || type == 5) {
		url = service.defaults.baseURL + '/admin/publics/downTemplate?type=' + type + '&token=' + getToken();
	} else {
		url = service.defaults.baseURL + '/admin/publics/downTemplate?file_name=' + fileName + '&token=' +
			getToken();
	}
	window.location.href = url
	// window.open(url, '_blank');
}


const isToday = function(str) {
	var d0 = new Date();
	var y0 = d0.getFullYear();
	var m0 = d0.getMonth() + 1;
	var d0 = d0.getDate();
	var d1 = new Date(str);
	var y1 = d1.getFullYear();
	var m1 = d1.getMonth() + 1;
	var d1 = d1.getDate();
	// console.log(y0);
	// console.log(m0);
	// console.log(d0);
	// console.log(y1);
	// console.log(m1);
	// console.log(d1);
	return y0 == y1 && m0 == m1 && d0 == d1;
}

const currentDate = function() {
	// 获取当前日期
	let currentDate = new Date();

	// 获取年、月、日
	let year = currentDate.getFullYear();
	let month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 月份从0开始，需要加1并补零
	let day = ('0' + currentDate.getDate()).slice(-2); // 获取日期并补零

	// 拼接成 "年-月-日" 格式的字符串
	let formattedDate = year + '-' + month + '-' + day;

	return formattedDate
}

const formatDate = function(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}

export {
	IsAppleStore,
	isWeiXin,
	strReplace,
	timeFormat,
	dateFormat,
	formatFutureTime,
	formatSeconds,
	dateIntervalFormat,
	dateFormatter,
	toChinesNum,
	getFutureData,
	futureTime,
	highLight,
	discriCard,
	noPassByName,
	noPassByPhone,
	noPassByIdent,
	noPassByBankCard,
	getDistance,
	geiWeekToArr,
	getIn,
	addChineseUnit,
	getDateStr,
	getWeekStr,
	formatDistance,
	getQueryString,
	isWxMini,
	getAge,
	isEmptyObject,
	number_format,
	isMobile,
	downLoadExcel,
	debounce_new,
	isToday,
	downloadFile,
	currentDate,
	formatDate
}