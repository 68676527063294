import {
	constantRoutes
} from "@/router/index";
import home from "@/router/modules/home";
import operation from "@/router/modules/operation";
import setting from "@/router/modules/setting";
import {
	menuList
} from "@/api/system/index";
import {
	getLeftMenuStat
} from "@/api/public/index";
const state = {
	routers: [],
	addRouters: [],
	route_talent_num: {}, //左侧人才统计数
	route_job_num: {}, //左侧职位统计数
	route_process_num: {}, //左侧进程统计数
};
const mutations = {
	SET_ROUTERS: (state, routers) => {
		state.addRouters = routers;
		state.routers = constantRoutes.concat(routers);
	},
	SET_ROUTE_TALENT_NUM: (state, data) => {
		state.route_talent_num = data;
	},
	SET_ROUTE_PROCESS_NUM: (state, data) => {
		state.route_process_num = data;
	},
};
const actions = {
	GenerateRoutes({
		commit
	}, data) {
		return new Promise((resolve) => {
			let menuList = [
				// {
				// 	path: '/resource',
				// 	component: () => import('@/views/resource/index.vue'),
				// 	name: 'operationClassify',
				// 	redirect: '/resource/voiceCommunication',
				// 	meta: {
				// 		title: '语音通信'
				// 	},
				// 	children: [{
				// 			path: '/resource/voiceCommunication',
				// 			component: () => import(
				// 				'@/views/resource/voiceCommunication/index.vue'),
				// 			name: 'voiceCommunication',
				// 			redirect: '/resource/voiceCommunication/list',
				// 			meta: {
				// 				title: '资源管理'
				// 			},
				// 			children: [{
				// 					path: '/resource/voiceCommunication/list',
				// 					component: () => import(
				// 						'@/views/resource/voiceCommunication/list.vue'
				// 						),
				// 					name: 'resourceManagementList',
				// 					meta: {
				// 						title: '资源管理',
				// 						isShow: true,
				// 						activeMenu: '/resource/voiceCommunication'
				// 					},
				// 				}, {
				// 					path: '/resource/voiceCommunication/add',
				// 					component: () => import(
				// 						'@/views/resource/voiceCommunication/add.vue'
				// 						),
				// 					name: 'resourceManagementAdd',
				// 					meta: {
				// 						title: '新增资源',
				// 						isShow: true,
				// 						activeMenu: '/resource/voiceCommunication'
				// 					},
				// 				},
				// 				{
				// 					path: '/resource/voiceCommunication/edit',
				// 					component: () => import(
				// 						'@/views/resource/voiceCommunication/edit.vue'
				// 						),
				// 					name: 'resourceManagementEdit',
				// 					meta: {
				// 						title: '编辑资源',
				// 						isShow: true,
				// 						activeMenu: '/resource/voiceCommunication'
				// 					},
				// 				},
				// 			]
				// 		},
				// 		{
				// 			path: '/resource/locallineManagement',
				// 			component: () => import(
				// 				'@/views/resource/locallineManagement/index.vue'),
				// 			name: 'locallineManagement',
				// 			redirect: '/resource/locallineManagement/list',
				// 			meta: {
				// 				title: '本地线路管理'
				// 			},
				// 			children: [{
				// 					path: '/resource/locallineManagement/list',
				// 					component: () => import(
				// 						'@/views/resource/locallineManagement/list.vue'
				// 						),
				// 					name: 'locallineManagementList',
				// 					meta: {
				// 						title: '本地线路管理',
				// 						isShow: true,
				// 						activeMenu: '/resource/locallineManagement'
				// 					},
				// 				},
				// 				{
				// 					path: '/resource/locallineManagement/add',
				// 					component: () => import(
				// 						'@/views/resource/locallineManagement/add.vue'
				// 						),
				// 					name: 'resourceManagementAdd',
				// 					meta: {
				// 						title: '新增本地线路',
				// 						isShow: true,
				// 						activeMenu: '/resource/locallineManagement'
				// 					},
				// 				},
				// 			]
				// 		},
				// 		{
				// 			path: '/resource/assignedLocalline',
				// 			component: () => import(
				// 				'@/views/resource/assignedLocalline/index.vue'),
				// 			name: 'assignedLocalline',
				// 			redirect: '/resource/assignedLocalline/list',
				// 			meta: {
				// 				title: '已分配的本地线路'
				// 			},
				// 			children: [{
				// 				path: '/resource/assignedLocalline/list',
				// 				component: () => import(
				// 					'@/views/resource/assignedLocalline/list.vue'
				// 					),
				// 				name: 'assignedLocallineList',
				// 				meta: {
				// 					title: '已分配的本地线路',
				// 					isShow: true,
				// 					activeMenu: '/resource/assignedLocalline'
				// 				},
				// 			}]
				// 		},
				// 	]
				// }
				{
					...home,
				},
				{
					...operation,
				},
				{
					...setting,
				},
			];
			var newRouterList = [];
			// menulist接口返回的路由信息带权限 routerList前端定义的路由信息
			function addRouteList(menulist, routerList) {
				// console.log(menulist && menulist.length > 0)
				if (menulist && menulist.length > 0) {
					var menuList = [];
					for (let menuIdx = 0; menuIdx < menulist.length; menuIdx++) {
						const menuItem = menulist[menuIdx];
						for (let routerIdx = 0; routerIdx < routerList.length; routerIdx++) {
							const routerItem = routerList[routerIdx];
							var newRouterItem = {
								...routerItem,
							};
							newRouterItem["meta"] = {
								...newRouterItem["meta"],
							};
							if (menuItem["path"] == routerItem["path"]) {
								delete newRouterItem["children"];
								newRouterItem.meta.menu_id = menuItem.menu_id;
								newRouterItem.meta.is_customize = menuItem.is_customize;
								newRouterItem.meta.mark = menuItem.mark; //根据mark更新左侧边栏的数量
								// // console.log(newRouterItem.meta)
								if (newRouterItem.meta.title == "") {
									//处理自定义内容
									var pathAry = newRouterItem.path.split(":");
									newRouterItem.path = pathAry[0] + menuItem.customize_id;
									newRouterItem.meta.title = menuItem.menu_name;
									newRouterItem.meta.favorites_id = menuItem.customize_id; //收藏夹ID
								}
								var menuItem2 = newRouterItem;
								if (
									menuItem["children"] != undefined &&
									menuItem["children"].length > 0 &&
									routerItem["children"] != undefined &&
									routerItem["children"].length > 0
								) {
									var childItem = addRouteList(
										menuItem["children"],
										routerItem["children"]
									);
									menuItem2["children"] = childItem;
								}
								// 四级处理
								if (
									menuItem.level == 3 &&
									routerItem["children"] != undefined &&
									routerItem["children"].length > 0
								) {
									menuItem2["children"] = [...routerItem["children"]];
								}
								menuList.push(menuItem2);
							}
						}
					}
				} else {
					window.location.reload()
				}
				return menuList;
			}
			newRouterList = addRouteList(data, menuList);
			commit("SET_ROUTERS", newRouterList);
			resolve();
		});
	},
	getMenuList() {
		return menuList();
	},
	//获取左侧菜单统计
	getLeftMenuStatistics({
		commit
	}, params) {
		return new Promise((resolve, reject) => {
			getLeftMenuStat(params)
				.then((result) => {
					if (result.code == "0000") {
						switch (params.mark) {
							case "talent":
								commit("SET_ROUTE_TALENT_NUM", result.data);
								break;
							default:
								break;
						}
					}
					resolve(result);
				})
				.catch((err) => {
					// console.log(err);
					reject();
				});
		});
	},
};
export default {
	namespaced: true,
	state,
	mutations,
	actions,
};