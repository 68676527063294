import router from "./router";
import store from "./store";
import Vue from 'vue'
import getPageTitle from "@/utils/get-page-title.js";
import {
	getToken
} from "./utils/auth";

var whiteList = [
	"/login",
	"/retrievePassword",
	"/404",
];

const first_request = store.state.common['first_request']
router.beforeEach(async (to, from, next) => {
	Vue.nextTick(() => {
		from.meta.isSelected = false;
		to.meta.isSelected = true;
		document.title = getPageTitle(to.meta.title);
		// 通过token查看登录没有 如果有token就进入系统
		const hasToken = getToken();
		if (hasToken) {
			const minute = new Date().getMinutes()
			if (minute % 5 == 0) { //5的倍数调用
				// store.dispatch("common/initRemindNotice"); //切换页面要调一次消息通知
			}
			// 查看进入的是不是登录页面 如果是登录页面则进入系统首页
			if (to.path === "/login") {
				next({
					path: "/",
				});
			} else {
				// 查看是否有用户信息 如果有则进入系统 如果没有 请求获取用户信息
				const hasGetUserInfo = store.state.user.name;
				if (hasGetUserInfo) {
					next();
				} else {
					// 设置用户信息
					var name = store.state.user.userInfo.user_name ?
						store.state.user.userInfo :
						JSON.parse(store.state.user.userInfo);
					store.commit("user/SET_USERNAME", name.user_name);
					// let {
					// 	data
					// } = await store.dispatch("routingTable/getMenuList");
					let menuData = [{
						"menu_id": 2,
						"pid": 0,
						"menu_name": "工作台",
						"mark": "operation",
						"path": "/operation",
						"level": 1,
						"is_customize": 0,
						"children": [{
								"menu_id": 7,
								"pid": 2,
								"menu_name": "语音通信",
								"mark": "",
								"path": "/operation/resource",
								"level": 2,
								"is_customize": 0,
								"children": [{
										"menu_id": 8,
										"pid": 7,
										"menu_name": "资源管理",
										"mark": "all_resource",
										"path": "/operation/resource/voiceCommunication",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 9,
										"pid": 7,
										"menu_name": "本地线路管理",
										"mark": "localline",
										"path": "/operation/resource/locallineManagement",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 10,
										"pid": 7,
										"menu_name": "已分配本地线路",
										"mark": "assigned_localline",
										"path": "/operation/resource/assignedLocalline",
										"level": 3,
										"is_customize": 0
									}
								]
							},
							{
								"menu_id": 14,
								"pid": 2,
								"menu_name": "工作号管理",
								"mark": "",
								"path": "/operation/numberManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
										"menu_id": 20,
										"pid": 14,
										"menu_name": "报备企业",
										"mark": "numberManagement_talent",
										"path": "/operation/numberManagement/numCompany",
										"level": 3,
										"is_customize": 0
									}, {
										"menu_id": 15,
										"pid": 14,
										"menu_name": "全部号码",
										"mark": "numberManagement_talent",
										"path": "/operation/numberManagement/allNumber",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 16,
										"pid": 14,
										"menu_name": "工作号已分配",
										"mark": "numberManagement_talent",
										"path": "/operation/numberManagement/workNumAssigned",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 17,
										"pid": 14,
										"menu_name": "工作号已标记",
										"mark": "numberManagement_talent",
										"path": "/operation/numberManagement/workNumMarked",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 18,
										"pid": 14,
										"menu_name": "工作号回收站",
										"mark": "numberManagement_talent",
										"path": "/operation/numberManagement/workNumRecycled",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 19,
										"pid": 14,
										"menu_name": "工作号即将到期",
										"mark": "numberManagement_talent",
										"path": "/operation/numberManagement/workNumWillexpire",
										"level": 3,
										"is_customize": 0
									},
									// {
									// 	"menu_id": 21,
									// 	"pid": 14,
									// 	"menu_name": "号码操作日志",
									// 	"mark": "numberManagement_talent",
									// 	"path": "/operation/numberManagement/workNumOperatelog",
									// 	"level": 3,
									// 	"is_customize": 0
									// }
								]
							},
							{
								"menu_id": 14,
								"pid": 2,
								"menu_name": "语音池管理",
								"mark": "",
								"path": "/operation/voicePoolManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
										"menu_id": 20,
										"pid": 14,
										"menu_name": "已开通号码",
										"mark": "voicePoolopened",
										"path": "/operation/voicePoolManagement/openedNum",
										"level": 3,
										"is_customize": 0
									}, {
										"menu_id": 15,
										"pid": 14,
										"menu_name": "已退订号码",
										"mark": "voicePoolunsubscribe",
										"path": "/operation/voicePoolManagement/unsubscribeNum",
										"level": 3,
										"is_customize": 0
									}
								]
							},
							{
								"menu_id": 14,
								"pid": 2,
								"menu_name": "回铃号管理",
								"mark": "",
								"path": "/operation/ringbackNumber",
								"level": 2,
								"is_customize": 0,
								"children": [{
										"menu_id": 15,
										"pid": 14,
										"menu_name": "号码池",
										"mark": "ringback_all_number",
										"path": "/operation/ringbackNumber/allNumber",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 16,
										"pid": 14,
										"menu_name": "回铃号已分配",
										"mark": "ringback_assigned_number",
										"path": "/operation/ringbackNumber/assigned",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 17,
										"pid": 14,
										"menu_name": "回铃号已标记",
										"mark": "ringback_marked_number",
										"path": "/operation/ringbackNumber/marked",
										"level": 3,
										"is_customize": 0
									},
									{
										"menu_id": 18,
										"pid": 14,
										"menu_name": "回铃号回收站",
										"mark": "ringback_recycled_number",
										"path": "/operation/ringbackNumber/recycled",
										"level": 3,
										"is_customize": 0
									}
								]
							},
							{
								"menu_id": 15,
								"pid": 2,
								"menu_name": "供应商管理",
								"mark": "",
								"path": "/operation/supplierManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
									"menu_id": 16,
									"pid": 15,
									"menu_name": "供应商管理",
									"mark": "supplierManagement",
									"path": "/operation/supplierManagement/supplierList",
									"level": 3,
									"is_customize": 0
								}]
							},
							{
								"menu_id": 16,
								"pid": 2,
								"menu_name": "渠道管理",
								"mark": "",
								"path": "/operation/channelManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
									"menu_id": 17,
									"pid": 16,
									"menu_name": "渠道管理",
									"mark": "channelManagement",
									"path": "/operation/channelManagement/channelList",
									"level": 3,
									"is_customize": 0
								}]
							},
							{
								"menu_id": 17,
								"pid": 2,
								"menu_name": "企业管理",
								"mark": "",
								"path": "/operation/companyManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
									"menu_id": 18,
									"pid": 17,
									"menu_name": "报备企业",
									"mark": "companyManagement",
									"path": "/operation/companyManagement/companyList",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 19,
									"pid": 17,
									"menu_name": "渠道企业",
									"mark": "agentComlist",
									"path": "/operation/companyManagement/agentComlist",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 20,
									"pid": 17,
									"menu_name": "企业主叫",
									"mark": "companyCallerList",
									"path": "/operation/companyManagement/companyCallerList",
									"level": 3,
									"is_customize": 0
								}]
							},
							{
								"menu_id": 17,
								"pid": 2,
								"menu_name": "报备材料",
								"mark": "",
								"path": "/operation/reportingMaterials",
								"level": 2,
								"is_customize": 0,
								"children": [{
									"menu_id": 18,
									"pid": 17,
									"menu_name": "材料模板",
									"mark": "templatelist",
									"path": "/operation/reportingMaterials/templatelist",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 19,
									"pid": 17,
									"menu_name": "模板字段",
									"mark": "templateFieldlist",
									"path": "/operation/reportingMaterials/templateFieldlist",
									"level": 3,
									"is_customize": 0
								}]
							},
							{
								"menu_id": 15,
								"pid": 2,
								"menu_name": "财务管理",
								"mark": "",
								"path": "/operation/financeManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
									"menu_id": 16,
									"pid": 15,
									"menu_name": "供应商余额流水明细",
									"mark": "supplierBalanceList",
									"path": "/operation/financeManagement/supplierBalanceList",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 16,
									"pid": 15,
									"menu_name": "渠道余额流水明细",
									"mark": "agentBalanceList",
									"path": "/operation/financeManagement/agentBalanceList",
									"level": 3,
									"is_customize": 0
								}]
							},
							{
								"menu_id": 15,
								"pid": 2,
								"menu_name": "话单管理",
								"mark": "",
								"path": "/operation/cdrManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
									"menu_id": 16,
									"pid": 15,
									"menu_name": "供应商话单管理",
									"mark": "cdrListSupplier",
									"path": "/operation/cdrManagement/cdrListSupplier",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 16,
									"pid": 15,
									"menu_name": "渠道话单管理",
									"mark": "cdrListAgent",
									"path": "/operation/cdrManagement/cdrListAgent",
									"level": 3,
									"is_customize": 0
								}]
							},
							{
								"menu_id": 17,
								"pid": 2,
								"menu_name": "系统管理",
								"mark": "",
								"path": "/operation/systemManagement",
								"level": 2,
								"is_customize": 0,
								"children": [{
									"menu_id": 18,
									"pid": 17,
									"menu_name": "用户管理",
									"mark": "userManagement",
									"path": "/operation/systemManagement/userList",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 19,
									"pid": 17,
									"menu_name": "角色管理",
									"mark": "roleManagement",
									"path": "/operation/systemManagement/roleList",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 21,
									"pid": 17,
									"menu_name": "行业管理",
									"mark": "industryManagement",
									"path": "/operation/systemManagement/industryList",
									"level": 3,
									"is_customize": 0
								}, {
									"menu_id": 20,
									"pid": 17,
									"menu_name": "日志管理",
									"mark": "logsManagement",
									"path": "/operation/systemManagement/logsList",
									"level": 3,
									"is_customize": 0
								}]
							}
						]
					}]

					store.dispatch("routingTable/GenerateRoutes", menuData).then(() => {
						let routersList = store.state.routingTable.addRouters;
						router.addRoutes(routersList);
						next({
							...to,
							replace: true,
						});
					});
				}
			}
		} else {
			if (whiteList.indexOf(to.path) != -1) {
				next();
			} else {
				next(`/login?redirect=${to.path}`);
			}
		}
	});
});