import {
	getOperateAuthList,
	getRemindNotice,
	getAreaList,
	getSupplierList,
	getProfileTemplateList,
	getAgentList,
	getLocalLineList,
	getCompanyListSelect,
	getReportCompanyList,
	getIndustryList,
	getProfileFieldList,
	getCallModeList,
	getResourceList,
	getRoleList
} from "@/api/public/index";
const state = {
	platformName: '运营管理', // 渠道管理平台
	dialogW: '524px', //弹窗宽度
	drawerW: '750px', //抽屉宽度
	options_area_list: [], // 地区-3级
	options_area_list_level2: [], //地区-2级
	options_resource_list: [], //资源下拉
	options_supplier_resource_list: [], //供应商下资源
	options_role_list: [], //角色下拉
	// 号码来源 1|手动添加，2|批量导入，3|已分配撤回，4|已标记撤回
	options_from_api: [{
		label: '中移',
		value: 1
	}, {
		label: '卓望',
		value: 2
	}],
	options_number_source: [{
		label: '手动添加',
		value: 1
	}, {
		label: '批量导入',
		value: 2
	}, {
		label: '已分配撤回',
		value: 3
	}, {
		label: '已标记撤回',
		value: 4
	}],
	//X号状态：0|不透传，1|透传B，2|透传A',
	options_xnumber_status: [{
		label: '不透传',
		value: 0
	}, {
		label: '透传B',
		value: 1
	}, {
		label: '透传A',
		value: 2
	}],
	//到期范围  1 已到期 , 2 今日到期 , 3已到期3天 , 7已到期3天 , 15已到期15天 , 150 已到期15天以上 103 3天内到期，107 7天内到期 ， 115 15天内到期
	options_expire_type: [{
		label: '已到期',
		value: 1
	}, {
		label: '今日到期',
		value: 2
	}, {
		label: '已到期3天',
		value: 3
	}, {
		label: '已到期7天',
		value: 7
	}, {
		label: '已到期15天',
		value: 15
	}, {
		label: '已到期15天以上',
		value: 150
	}, {
		label: '3天内到期',
		value: 103
	}, {
		label: '7天内到期',
		value: 107
	}, {
		label: '15天内到期',
		value: 115
	}],
	options_is_connect_list: [{
			label: '已接通',
			value: 1
		},
		{
			label: '未接通',
			value: 0
		}
	], //通话状态
	options_call_duration: [{
			label: '30秒',
			value: 1
		},
		{
			label: '1分钟',
			value: 2
		},
		{
			label: '2分钟',
			value: 3
		},
		{
			label: '3分钟',
			value: 4
		},
		{
			label: '大于3分钟',
			value: 5
		}
	], //长通话
	options_operate_type: [{
			label: '充值',
			value: 1
		},
		{
			label: '话单扣减',
			value: 2
		},
		{
			label: '号码费扣减',
			value: 3
		},
		{
			label: '语音池功能费',
			value: 4
		}
	], //操作类型
	options_collection_method: [{
		label: '支付宝',
		value: 1
	}, {
		label: '微信',
		value: 2
	}, {
		label: '银行卡',
		value: 3
	}],
	options_audit_data: [{
			label: '审核通过',
			id: 1
		},
		{
			label: '审核驳回',
			id: 2
		}
	],
	options_audit_status: [{
			label: '全部',
			id: ''
		},
		{
			label: '待审核',
			id: 0
		},
		{
			label: '审核通过',
			id: 1
		},
		{
			label: '审核驳回',
			id: 2
		}
	], //审核状态
	options_supplier_type: [{
			type: '类型1',
			value: 1
		},
		{
			type: '类型2',
			value: 2
		}
	], //供应商类型
	options_source_status: [{
			label: '系统添加',
			value: 1
		},
		{
			label: '自行注册',
			value: 2
		}
	], //渠道来源
	options_supplier_list: [], //供应商下拉
	options_profile_template: [], //材料模板下拉
	options_agent_list: [], //渠道下拉
	options_localline_list: [], //本地线路
	options_company_list: [], //企业下拉
	options_is_report_company: [], //报备企业下拉
	options_industry_list: [], //行业下拉
	options_profilefield_list: [], //材料模板字段列表
	options_x_status_list: [{
			label: '不透传',
			value: 0
		},
		{
			label: '透传B',
			value: 1
		},
		{
			label: '透传A',
			value: 2
		}
	],
	options_report: [{
		label: '不上报',
		value: 0
	}, {
		label: '上报',
		value: 1
	}],
	options_charging: [{
		label: '是',
		value: 1
	}, {
		label: '否',
		value: 0
	}], //是否扣费
	options_work: [{
		label: '不是',
		value: 0
	}, {
		label: '是',
		value: 1
	}],
	options_work_from_api: [{
		label: '中移',
		value: 1
	}, {
		label: '卓望',
		value: 2
	}],
	options_is_admin: [{
		label: '是',
		value: 1
	}, {
		label: '否',
		value: 0
	}],
	options_acount_list: [{
		label: '渠道',
		value: 2
	}, {
		label: '平台',
		value: 1
	}],
	options_call_mode: [], // 呼叫模式下拉
	options_number_type: [{
		label: '普通XB',
		value: 4
	}, {
		label: '普通AXB',
		value: 5
	}, {
		label: '普通回拨',
		value: 6
	}], // 呼叫模式下拉
	options_charge_way: [{
		label: '按量计费',
		value: 1
	}], //计费方式
	options_is_open_package: [{
		label: '是',
		value: 1
	}, {
		label: '否',
		value: 0
	}],// 是否加入语音池
	options_is_sync: [{
		label: '仅解绑',
		value: 0
	}, {
		label: '退订语音池并解绑',
		value: 1
	}],// 解绑选项
	options_blacklist_ability: [{
			label: '是',
			value: 1
		},
		{
			label: '否',
			value: 0
		}
	], //黑名单能力
	options_bill_methods: [{
			label: '按量计费',
			value: 1
		}
		// ,
		// {
		// 	label: '按语音包计费',
		// 	value: 2
		// }
	], //计费方式
	options_lowelimination: [{
			label: '是',
			value: 1
		},
		{
			label: '否',
			value: 0
		}
	], //是否低消
	options_blacklist: [{
			label: '是',
			value: 1
		},
		{
			label: '否',
			value: 0
		}
	], //黑名单能力
	options_caller_fixed_tel: [{
			label: '是',
			value: 1
		},
		{
			label: '否',
			value: 0
		}
	], //主叫是否支持固话 
	options_callee_fixed_tel: [{
			label: '是',
			value: 1
		},
		{
			label: '否',
			value: 0
		}
	], //被叫是否支持固话
	options_callee_four_hundred: [{
			label: '是',
			value: 1
		},
		{
			label: '否',
			value: 0
		}
	], //被叫是否支持400
	options_reporting_materials: [{
			label: '材料1',
			value: 0
		},
		{
			label: '材料2',
			value: 1
		},
		{
			label: '材料3',
			value: 2
		}
	], //报备材料
	options_line_rules: [{
		label: '规则1',
		value: 0
	}, {
		label: '规则2',
		value: 1
	}, {
		label: '规则3',
		value: 2
	}], //线路规则
	options_num_status: [{
		label: '未绑定',
		value: 1
	}, {
		label: '已绑定',
		value: 2
	}, {
		label: '未实名',
		value: 3
	}, {
		label: '已实名',
		value: 4
	}, {
		label: '认证失败',
		value: 5
	}], //号码状态
	options_mark_day_list: [{
		label: '1日',
		value: 1
	}, {
		label: '2日',
		value: 2
	}, {
		label: '3日',
		value: 3
	}, {
		label: '4日',
		value: 4
	}, {
		label: '5日',
		value: 5
	}, {
		label: '6日',
		value: 6
	}, {
		label: '7日',
		value: 7
	}, {
		label: '8日',
		value: 8
	}, {
		label: '9日',
		value: 9
	}, {
		label: '10日',
		value: 10
	}, {
		label: '11日',
		value: 11
	}, {
		label: '12日',
		value: 12
	}, {
		label: '13日',
		value: 13
	}, {
		label: '14日',
		value: 14
	}, {
		label: '15日',
		value: 15
	}, {
		label: '16日',
		value: 16
	}, {
		label: '17日',
		value: 17
	}, {
		label: '18日',
		value: 18
	}, {
		label: '19日',
		value: 19
	}, {
		label: '20日',
		value: 20
	}, {
		label: '21日',
		value: 21
	}, {
		label: '22日',
		value: 22
	}, {
		label: '23日',
		value: 23
	}, {
		label: '24日',
		value: 24
	}, {
		label: '25日',
		value: 25
	}, {
		label: '26日',
		value: 26
	}, {
		label: '27日',
		value: 27
	}],
	// 呼叫模式：1|X号模式，2|直呼模式
	options_call_mode_select: [{
		label: 'X号模式',
		value: 1
	}, {
		label: '直呼模式',
		value: 2
	}],
	options_sex: [], //性别
	options_education: [], //学历
	options_marriage: [], //婚姻状况
	options_talent_status: [], //人才状态
	options_language: [], //语言能力
	options_talent_source: [], //人才来源
	options_work_nature: [], //工作性质
	options_job_priority: [], //职位优先级
	options_job_status: [], //职位状态
	options_district: [], //工作地区
	options_job_category: [], //职位类别
	options_work_experience: [], //工作经验
	options_tag: [], //标签
	options_process_status: [], //职位状态
	options_trade_category: [], //行业类别
	options_contact_list: [], //联系人列表
	options_operate_auth: {}, //操作权限
	options_repeat_way: [], //重复方式
	options_remind_category: [], //提醒类别
	options_remind_duration: [], //提醒时长
	first_request: true,
}
const mutations = {
	SET_OPTIONS_SEX: (state, data) => {
		state.options_sex = data
	},
	SET_OPTIONS_EDUCATION: (state, data) => {
		state.options_education = data
	},
	SET_OPTIONS_MARRIAGE: (state, data) => {
		state.options_marriage = data
	},
	SET_OPTIONS_TALENT_STATUS: (state, data) => {
		state.options_talent_status = data
	},
	SET_OPTIONS_LANGUAGE: (state, data) => {
		state.options_language = data
	},
	SET_OPTIONS_TALENT_SOURCE: (state, data) => {
		state.options_talent_source = data
	},
	SET_OPTIONS_WORK_NATURE: (state, data) => {
		state.options_work_nature = data
	},
	SET_OPTIONS_JOB_PRIORITY: (state, data) => {
		state.options_job_priority = data
	},
	SET_OPTIONS_JOB_STATUS: (state, data) => {
		state.options_job_status = data
	},
	SET_OPTIONS_DISTRICT: (state, data) => {
		state.options_district = data
	},
	SET_OPTIONS_JOB_CATEGORY: (state, data) => {
		state.options_job_category = data
	},
	SET_OPTIONS_WORK_EXPERIENCE: (state, data) => {
		state.options_work_experience = data
	},
	SET_OPTIONS_PROCESS_STATUS: (state, data) => {
		state.options_process_status = data
	},
	SET_OPTIONS_TRADE_CATEGORY: (state, data) => {
		state.options_trade_category = data
	},
	SET_CONTACT_LIST: (state, data) => {
		state.options_contact_list = data
	},
	SET_OPERATE_AUTH: (state, data) => {
		state.options_operate_auth = data
	},
	SET_OPTIONS_REMIND_CATEGORY: (state, data) => {
		state.options_remind_category = data
	},
	SET_OPTIONS_REMIND_DURATION: (state, data) => {
		state.options_remind_duration = data
	},
	SET_OPTIONS_REPEAT_WAY: (state, data) => {
		state.options_repeat_way = data
	},
	SET_FIRST_REQUEST: (state, data) => {
		state.first_request = data
	},
	SET_TAGLISTS: (state, data) => {
		state.options_tag = data
	},
	SET_AREA_LIST: (state, data) => {
		state.options_area_list = data
	},
	SET_AREA_LIST_LEVEL_2: (state, data) => {
		state.options_area_list_level2 = data
	},
	SET_SUPPLIER_LIST: (state, data) => {
		state.options_supplier_list = data
	},
	SET_PROFILETEMPLATE_LIST: (state, data) => {
		state.options_profile_template = data
	},
	SET_AGENT_LIST: (state, data) => {
		state.options_agent_list = data
	},
	SET_LOCALLINE_LIST: (state, data) => {
		state.options_localline_list = data
	},
	SET_COMPANY_LIST: (state, data) => {
		state.options_company_list = data
	},
	SET_IS_REPORT_COMPANY: (state, data) => {
		state.options_is_report_company = data
	},
	SET_SUPPLIER_RESOURCE_LIST: (state, data) => {
		state.options_supplier_resource_list = data
	},
	SET_INDUSTRY_LIST: (state, data) => {
		state.options_industry_list = data
	},
	SET_PROFILEFIELD_LIST: (state, data) => {
		state.options_profilefield_list = data
	},
	SET_CALL_MODE: (state, data) => {
		state.options_call_mode = data
	},
	SET_RESOURCE_LIST: (state, data) => {
		state.options_resource_list = data
	},
	SET_ROLE_LIST: (state, data) => {
		state.options_role_list = data
	}
}

const actions = {
	// 获取地区 - 3级
	getAreaList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getAreaList().then((result) => {
				if (result.code == '0000') {
					let data = result.data
					data.map(item => {
						item.value = item.id
						item.label = item.name
						if (item.children && item.children.length > 0) {
							item.children.map(item1 => {
								item1.value = item1.id
								item1.label = item1.name
								if (item1.children && item1.children.length > 0) {
									item1.children.map(item2 => {
										item2.value = item2.id
										item2.label = item2.name
									})
								}
							})
						}
					})
					commit('SET_AREA_LIST', data)
				}
				commit('SET_FIRST_REQUEST', false)
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	// 获取地区 - 2级
	getAreaListLevel2({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getAreaList({
				level: 2
			}).then((result) => {
				if (result.code == '0000') {
					let data = result.data
					data.map(item => {
						item.value = item.id
						item.label = item.name
						if (item.children && item.children.length > 0) {
							item.children.map(item1 => {
								item1.value = item1.id
								item1.label = item1.name
							})
						}
					})
					commit('SET_AREA_LIST_LEVEL_2', data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	// 呼叫模式下拉
	getCallModeList({
		commit
	}, params) { //type=1：呼叫模式；type=2：工作号号码类型；type=3：回铃号号码类型
		return new Promise((resolve, reject) => {
			getCallModeList(params).then((result) => {
				if (result.code == '0000') {
					commit('SET_CALL_MODE', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	//资源下拉
	getResourceList({
		commit
	}, params) {
		return new Promise((resolve, reject) => {
			getResourceList(params).then((result) => {
				if (result.code == '0000') {
					commit('SET_RESOURCE_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	// 供应商下资源下拉
	getSupplierResourceList({
		commit
	}, supplier_id) {
		return new Promise((resolve, reject) => {
			getResourceList({
				supplier_id: supplier_id
			}).then((result) => {
				if (result.code == '0000') {
					commit('SET_SUPPLIER_RESOURCE_LIST', result.data);
				}
				resolve(result);
			}).catch((err) => {
				// console.log(err)
				reject(err);
			});
		});
	},
	getRoleList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getRoleList().then((result) => {
				if (result.code == '0000') {
					commit('SET_ROLE_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	// 供应商下拉列表
	getSupplierList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getSupplierList().then((result) => {
				if (result.code == '0000') {
					commit('SET_SUPPLIER_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	// 报备材料模板下拉
	getProfileTemplateList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getProfileTemplateList().then((result) => {
				if (result.code == '0000') {
					commit('SET_PROFILETEMPLATE_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	// 渠道下拉
	getAgentList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getAgentList().then((result) => {
				if (result.code == '0000') {
					commit('SET_AGENT_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	// 本地线路规则
	getLocalLineList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getLocalLineList().then((result) => {
				if (result.code == '0000') {
					commit('SET_LOCALLINE_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	getCompanyListSelect({
		commit
	}, params) {
		return new Promise((resolve, reject) => {
			getCompanyListSelect(params).then((result) => {
				if (result.code == '0000') {
					commit('SET_COMPANY_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	getIsReportCompanyListSelect({
		commit
	}, params) {
		return new Promise((resolve, reject) => {
			getReportCompanyList(params).then((result) => {
				if (result.code == '0000') {
					commit('SET_IS_REPORT_COMPANY', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	getIndustryList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getIndustryList().then((result) => {
				if (result.code == '0000') {
					let data = result.data
					data.map(item => {
						item.label = item.name
						item.value = item.id
						if (item.children && item.children.length > 0) {
							item.children.map(items => {
								items.label = items.name
								items.value = items.id
							})
						}
					})
					commit('SET_INDUSTRY_LIST', data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	getProfileFieldList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getProfileFieldList().then((result) => {
				if (result.code == '0000') {
					commit('SET_PROFILEFIELD_LIST', result.data)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	//获取操作权限 
	getOperateAuth({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getOperateAuthList().then((result) => {
				if (result.code == '0000') {
					var operate_auth = result.data
					commit('SET_OPERATE_AUTH', operate_auth)
				}
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
	//获取消息通知 
	initRemindNotice({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getRemindNotice().then((result) => {
				resolve(result)
			}).catch((err) => {
				// console.log(err)
				reject()
			});
		})
	},
}

const getters = {
	first_request: state => {
		return state.first_request;
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}